import BaseTextComponent from "@/components/base/el/BaseTextComponent";
import {get_esc_data, get_att_data, is_object} from "@/components/base/utils";
import {apiContact} from "@/apis/contact";
import BSN from "bootstrap.native/dist/bootstrap-native.esm.min.js";
import {is_array} from "@/components/base/utils"
import {apiGetForms} from "@/apis/forms.js";

export default {
    name: "BaseFormDataComponent",
    mixins: [BaseTextComponent],
    render(createElement) {
        return this.make_element_tree(createElement, this.component_data);
    },
    data() {
        return {
            is_text: false,
        };
    },
    async created() {
        if (this.component_data.attr && this.component_data.attr["deepsel-form-data"]) {
            let res = await apiGetForms(this.component_data.attr["deepsel-form-data"])
            let form = res.data[0]
            let origin_form = JSON.stringify(this.component_data)
            let form_fields = form.fields
            let formFields = form_fields.map(v => {
                switch (v.type) {
                    case 'select': {
                        let select_component = this.find_form_field(JSON.parse(origin_form), 'deepsel-form-select')
                        if (select_component) {
                            if (select_component.attr){
                                delete select_component.attr['deepsel-form-' + v.type]
                                delete select_component.attr['hidden']
                            }
                            if (select_component.attr && select_component.attr.class) {
                                if (is_array(select_component.attr.class)) select_component.attr.class = select_component.attr.class.join(' ')
                                select_component.attr.class = select_component.attr.class.replace(/(^|\s)col-\S+/g, '') + ' col-lg-' + 12 * eval(v.size)
                            }
                            let select_tag = this.find_form_tag(select_component, v.type)
                            if (select_tag) {
                                if (select_tag.attr) {
                                    delete select_tag.attr['hidden']
                                    select_tag.attr.name = v.name
                                    select_tag.attr['data-placeholder'] = v.name
                                    select_tag.attr.is_required = v.is_required
                                }
                                select_tag.child = v.selectable_values.map(o => ({
                                tag: 'option',
                                attr: {
                                    value: o.value,
                                    selected: o.selected
                                },
                                child: [{
                                    node: 'text',
                                    text: o.name
                                }],
                                node: 'element'
                                }))
                            }
                            return select_component
                        }
                        return {
                            tag: 'select',
                            attr: {
                                class: ' col-lg-' + 12 * eval(v.size),
                                // ['deepsel-form-' + v.type]: '',
                                is_required: v.is_required
                            },
                            child: v.selectable_values.map(o => ({
                                tag: 'option',
                                attr: {
                                    value: o.value,
                                },
                                child: [{
                                    node: 'text',
                                    text: o.name
                                }],
                                node: 'element'
                            })),
                            node: 'element'
                        }
                    }
                    case 'textarea': {
                        let textarea_component = this.find_form_field(JSON.parse(origin_form), 'deepsel-form-textarea')
                        if (textarea_component) {
                            if (textarea_component.attr){
                                delete textarea_component.attr['deepsel-form-' + v.type]
                                delete textarea_component.attr['hidden']
                            }
                            if (textarea_component.attr && textarea_component.attr.class) {
                                if (is_array(textarea_component.attr.class)) textarea_component.attr.class = textarea_component.attr.class.join(' ')
                                textarea_component.attr.class = textarea_component.attr.class.replace(/(^|\s)col-\S+/g, '') + ' col-lg-' + 12 * eval(v.size)
                            }
                            let textarea_tag = this.find_form_tag(textarea_component, v.type)
                            if (textarea_tag && textarea_tag.attr) {
                                delete textarea_tag.attr['hidden']
                                textarea_tag.attr.name = v.name
                                textarea_tag.attr.placeholder = v.placeholder
                                textarea_tag.attr.is_required = v.is_required
                            }
                            return textarea_component
                        }
                        return {
                            tag: 'textarea',
                            attr: {
                                class: ' col-lg-' + 12 * eval(v.size),
                                placeholder: v.placeholder,
                                // ['deepsel-form-' + v.type]: '',
                                is_required: v.is_required
                            },
                            node: 'element'
                        }
                    }
                    default: {
                        let input_component = this.find_form_field(JSON.parse(origin_form), 'deepsel-form-input-' + v.type)
                        if (input_component) {
                            if (input_component.attr){
                                delete input_component.attr['deepsel-form-input-' + v.type]
                                delete input_component.attr['hidden']
                            }
                            if (input_component.attr && input_component.attr.class) {
                                if (is_array(input_component.attr.class)) input_component.attr.class = input_component.attr.class.join(' ')
                                input_component.attr.class = input_component.attr.class.replace(/(^|\s)col-\S+/g, '') + ' col-lg-' + 12 * eval(v.size)
                            }
                            let input_tag = this.find_form_tag(input_component, 'input', v.type)
                            if (input_tag && input_tag.attr) {
                                delete input_tag.attr['hidden']
                                input_tag.attr.placeholder = v.placeholder
                                input_tag.attr.name = v.name
                                input_tag.attr.value = v.value
                                input_tag.attr.is_required = v.is_required
                            }
                            let label_tag = this.find_form_tag(input_component, 'label')
                            if (label_tag) {
                                let text_node = label_tag.child.find(n => n.text)
                                if (text_node) text_node.text = v.label
                                else label_tag.child.push({
                                node: 'text',
                                text: ' ' + v.label
                                })
                            }
                            return input_component
                        }
                        return v.label ? {
                            tag: 'label',
                            attr: {
                                class: 'col-lg-' + 12 * eval(v.size),
                                is_required: v.is_required
                            },
                            child: [{
                                tag: 'input',
                                attr: {
                                type: v.type,
                                placeholder: v.placeholder,
                                // ['deepsel-form-input-' + v.type]: '',
                                name: v.name,
                                value: v.value,
                                },
                                node: 'element'
                            }, {
                                node: 'text',
                                text: ' ' + v.label
                            }],
                            node: 'element'
                        } : {
                            tag: 'input',
                            attr: {
                                type: v.type,
                                class: 'col-lg-' + 12 * eval(v.size),
                                placeholder: v.placeholder,
                                // ['deepsel-form-input-' + v.type]: '',
                                name: v.name,
                                value: v.value,
                                is_required: v.is_required
                            },
                            node: 'element'
                        }
                    }
                }
            })
            let noti_input = {
                tag: 'input',
                attr: {
                    type: 'hidden',
                    name: 'success_noti',
                    value: form.success_noti,
                },
                node: 'element'
            }
            formFields.push(noti_input)
            let submit_component = this.find_form_field(JSON.parse(origin_form), 'deepsel-form-submit')
            // delete submit_component.attr['deepsel-form-submit']
            delete submit_component.attr['hidden']
            if (submit_component) formFields.push(submit_component)
            if (window.$('select').data('select2')) {
                window.$('select').select2('destroy');
            }
            this.component_data.attr.name = form.name
            let component_child = JSON.parse(JSON.stringify(this.component_data.child))
            component_child = component_child.filter(c => {
                let del = true
                if (c.attr) {
                    for (const a in c.attr) {
                        if (a.includes('deepsel-form')) del = false
                    }
                }
                if (!del) return c
            })
            component_child.forEach(c => {
                c.attr['hidden'] = ''
                let input_tag = this.find_form_tag(c, 'input')
                if (input_tag) input_tag.attr['hidden'] = ''
                let textarea_tag = this.find_form_tag(c, 'textarea')
                if (textarea_tag) textarea_tag.attr['hidden'] = ''
                let select_tag = this.find_form_tag(c, 'select')
                if (select_tag) select_tag.attr['hidden'] = ''
            })
            this.component_data.child = component_child.concat(formFields)
        }
    },
    updated: function () {
        this.$nextTick(function () {
            let event = new CustomEvent('BaseFormDataComponentUpdated');
            document.dispatchEvent(event);
        })
    },
    computed: {
        data_object() {
            let class_array = [{"deepsel-focus": this.is_editing}]
            if (this.component_data.attr && this.component_data.attr.class) {
                let class_value

                if (Array.isArray(this.component_data.attr.class)) {
                    class_value = this.component_data.attr.class.join(" ")
                } else class_value = this.component_data.attr.class

                class_array.push(class_value)
            }

            return {
                class: class_array,
                attrs: {
                    contenteditable: this.$store.state.edit_mode && !this.component_data.attr['deepsel-esc'],
                    draggable: false,
                },
                style: this.styling,
                on: {
                    focusout: this.focus_out,
                    // input: this.on_input,
                    mouseenter: this.mouse_enter,
                    mousedown: this.mouse_down,
                },
            };
        },
    },
    methods: {
        focus_out() {
        },
        make_element_tree(createElement, element_data, options = {}, context = null) {
            let data = {}
            let el, props, attrs, style, children

            if (element_data.attr && "deepsel-form-data" in element_data.attr) {
                let class_array = [{"deepsel-focus": this.is_editing}]
                if (this.component_data.attr && this.component_data.attr.class) {
                    let class_value

                    if (Array.isArray(this.component_data.attr.class)) {
                        class_value = this.component_data.attr.class.join(" ")
                    } else class_value = this.component_data.attr.class

                    class_array.push(class_value)
                }
                data = {
                    class: class_array,
                    attrs: {
                        contenteditable: this.$store.state.edit_mode && !this.component_data.attr['deepsel-esc'],
                        draggable: false,
                    },
                    style: this.styling,
                    on: {
                        focusout: this.focus_out,
                        // input: this.on_input,
                        mouseenter: this.mouse_enter,
                        mousedown: this.mouse_down,
                    },
                }
            }

            [el, props] = this.get_el_props(element_data, context)
            attrs = this.get_attrs(element_data)
            children = this.get_children(element_data, createElement)
            style = this.get_style(element_data)

            if (props) data.props = props
            if (attrs) data.attrs = {...attrs, ...data.attrs}
            if (style) data.style = {...style, ...data.style}

            if (element_data.attr && 'deepsel-form-submit' in element_data.attr) {
                data = {
                    on: {
                        click: this.submit_form
                    },
                    ...data
                }
            }

            for (let key in options) data[key] = options[key]
            
            return createElement(
                el,
                data,
                children
            )
        },
        get_el_props(element_data) {
            let el = element_data.tag
            let props = null
            return [el, props]
        },
        get_attrs(element_data) {
            let attrs = {}
            if (element_data.attr) {

                for (let key in element_data.attr) {
                    if (key === 'style' && is_object(element_data.attr[key])) continue
                    if (key === 'type' && element_data.tag === 'button') element_data.attr[key] = 'button'
                    let value = element_data.attr[key];
                    attrs[key] = Array.isArray(value) ? value.join(" ") : value
                }

                if (element_data.tag === "script") {
                    attrs.type = "text/javascript";
                    attrs.defer = false;
                    attrs.async = false;
                }

                let att_data = get_att_data(element_data, this.$store.state.common_data)
                if (att_data) {
                    for (let key in att_data) {
                        attrs[key] = att_data[key]
                    }
                }

                this.replace_origin_urls(attrs, element_data)

                return attrs
            }
        },
        get_style(element_data) {
            if (element_data.attr && is_object(element_data.attr.style)) {
                let origin = this.$store.state.origin;
                let style = element_data.attr.style
                if (style.backgroundImage) {
                    if (
                        !style.backgroundImage.includes("https://") &&
                        !style.backgroundImage.includes("http://")
                    ) {
                        let background_img_path = style.backgroundImage
                            .replace('url(', '')
                            .replace(')', '')
                            .replace(/'/g, '')

                        // background_img_path = background_img_path.replace(/\\/g, " ")
                        if (background_img_path.slice(0, 1) !== "/") {
                            background_img_path = "/" + background_img_path;
                        }

                        style.backgroundImage = `url(${origin + background_img_path})`
                    }
                }
                return style
            } else return null
        },
        replace_origin_urls(attrs, element_data) {
            let origin = this.$store.state.origin;
            if (origin) {
                if (element_data.tag === "script" || element_data.tag === "img") {
                    if (attrs.src) {
                        if (
                            !attrs.src.includes("https://") &&
                            !attrs.src.includes("http://")
                        ) {
                            attrs.src = attrs.src.replace(/\\/g, "/");
                            if (attrs.src.slice(0, 1) !== "/") {
                                attrs.src = "/" + attrs.src;
                            }
                            attrs.src = origin + attrs.src;
                        }
                    }
                } else if (element_data.tag === "link") {
                    if (
                        !attrs.href.includes("https://") &&
                        !attrs.href.includes("http://") &&
                        attrs.href !== '#' &&
                        !attrs.href.includes('javascript:void')
                    ) {
                        attrs.href = attrs.href.replace(/\\/g, "/");
                        if (attrs.href.slice(0, 1) !== "/") {
                            attrs.href = "/" + attrs.href;
                        }
                        attrs.href = origin + attrs.href;
                    }
                }
            }
        },
        get_children(element_data, createElement) {
            let children = []
            let esc_data = get_esc_data(element_data, this.$store.state.common_data)
            if (element_data.child) {
                for (let item of element_data.child) {
                    if (item.node === "element") {

                        if (item.tag === "link" && item.attr && item.attr.href) {
                            if (item.attr.href.includes("bootstrap")) continue;
                        }

                        children.push(this.make_element_tree(createElement, item));

                    } else if (item.node === "text") {
                        let esc_data = get_esc_data(element_data, this.$store.state.common_data)
                        if (esc_data) {
                            children.push(esc_data)
                        } else children.push(item.text);
                    }
                }
                if (!element_data.child.find(e => e.node === "text") && esc_data) {
                    children = [esc_data]
                }
            } else if (esc_data) {
                children.push(esc_data);
            }

            if (this.transform && "deepsel-form-data" in element_data.attr) {
                children.push(createElement("style", [this.transform]))
            }
            return children
        },
        async submit_form() {
            let is_required = false
            let file_size 
            let params = {
                form_name: this.component_data.attr.name,
            };
            [...this.$el.querySelectorAll('input'), ...this.$el.querySelectorAll('select'), ...this.$el.querySelectorAll('textarea')].forEach(el => {
                if (el.getAttribute('is_required') == 'true' && ! el.value) {
                    is_required = true
                    el.style.border = "solid 1px red"
                } else el.style.border = null
                if (!el.hidden && el.type != 'hidden' && el.name != 'success_noti'){
                    if ((el.type == 'radio' || el.type == 'checkbox') && !el.checked) params[el.name]= ''
                    else params[el.name]= el.files ? el.files[0] : el.value
                    if (el.files && el.files[0]) {
                        file_size = ((el.files[0].size/1024)/1024).toFixed(4); // MB
                        if (file_size > 10) el.style.border = "solid 1px red"
                    }
                }
            })
            if (is_required) {
                this.form_submit_noti(null, 'danger', '<i class="fas fa-times"></i>', 'Please fill out required fields')
                return
            }
            if (file_size > 10) {
                this.form_submit_noti(null, 'danger', '<i class="fas fa-times"></i>', 'The maximum file size is 10MB')
                return
            }
            var formData = new FormData()
            for (let p in params) {
                formData.append(p, params[p])
            }
            const loading = this.$vs.loading({type:'points', color: '26,92,255'})
            try {
                if (process.env.NODE_ENV === "development") {
                    await apiContact(formData)
                } else {
                    if (this.component_data.events && this.component_data.events.submit && this.component_data.events.submit['open-dialog']) {
                        new BSN.Modal(`*[popup-id='${this.component_data.events.submit['open-dialog']}']`).toggle()
                    }
                    await apiContact(formData)
                }   
                loading.close()
                let noti_input = this.$el.querySelector('input[type=hidden][name=success_noti]')
                noti_input ? this.form_submit_noti(null, 'success', '<i class="fas fa-check"></i>', noti_input.value) : this.form_submit_noti()
            } catch (error) {
                loading.close()
                this.form_submit_noti(null, 'danger', '<i class="fas fa-times"></i>', `Đã có lỗi xảy ra! ${error}`)
            }
        },
        form_submit_noti(position = null, color = 'success', icon = '<i class="fas fa-check"></i>', text= `Submitted!`) {
            this.$vs.notification({
                icon,
                color,
                position,
                title: this.component_data.attr.name,
                text: text || 'Submitted!'
            })
        },
        find_form_field(component_data, field) {
            if (component_data.attr && field in component_data.attr) {
                return component_data;
            } else if (component_data.child != null) {
                var result = null;
                for (let i = 0; result == null && i < component_data.child.length; i++) {
                    result = this.find_form_field(component_data.child[i], field);
                }
                return result
            }
            return null
          },
        find_form_tag(component_data, tag, type = undefined) {
            if (type) {
                if (component_data.tag == tag && component_data.attr && component_data.attr.type == type) {
                    return component_data;
                } else if (component_data.child != null) {
                    var result = null;
                    for (let i = 0; result == null && i < component_data.child.length; i++) {
                        result = this.find_form_tag(component_data.child[i], tag, type);
                    }
                    return result
                }
                return null
            } else {
                if (component_data.tag == tag && component_data.attr) {
                    return component_data;
                } else if (component_data.child != null) {
                    let result = null;
                    for (let i = 0; result == null && i < component_data.child.length; i++) {
                        result = this.find_form_tag(component_data.child[i], tag, type);
                    }
                    return result
                }
                return null
            }
        },
    },
}